<template>
	<component :is="icon" />
</template>

<script>
export default {
	name: "LazyIcon",
	props: {
		type: {
			type: String,
		},
	},
	computed: {
		icon() {
			return () => import(`./icons/${this.type}.vue`);
		},
	},
};
</script>
